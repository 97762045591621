@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main: #000000;
  --primary: rgb(0, 79, 84);
}
@page {
  margin: 30px 0;
}

@media print {
  .header {
    padding-top: 0px !important;
    margin-top: 0;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans';
}
h1 {
  font-size: 34px;
  line-height: 1;
  font-weight: 700;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-family: Raleway;
}
h2 {
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
  margin: 0 0 25px 0;
  padding: 0;
  font-family: Raleway;
  color: var(--primary);
  text-transform: uppercase;
  border-bottom: solid var(--primary);
}
h3 {
  margin: 0 0 5px 0 !important;
  font-size: 16px;
  line-height: 19px;
  font-family: Raleway;
  color: var(--main);
  font-weight: bold;
  margin: 0;
}
h4 {
  margin: 0 0 5px 0;
  font-size: 16px;
  line-height: 19px;
  font-family: Raleway;
  color: var(--primary);
  font-weight: bold;
}
h5 {
  margin: 0 0 5px 0;
  font-size: 12px;
  line-height: 14px;
  font-family: Raleway;
  color: var(--main);
  font-weight: bold;
}
p,
span,
a,
li {
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  margin: 0 0 14px 0;
}

li a {
  color: inherit;
  text-decoration: underline;
}

.spacer {
  height: 0;
}

p + ul {
  margin-top: -5px;
}

table {
  border-spacing: 0;
}
td {
  position: relative;
}

.header {
  padding: 25px 50px;
}
.header h1,
.header h2 {
  color: var(--main);
  font-weight: 700;
}
.content {
  display: flex;
  margin: 0 50px;
}

.left {
  flex-grow: 2;
  margin: 0 40px 0 0;
}
.right {
  flex-grow: 1;
  width: 35%;
  min-width: 35%;
}
.block {
  margin-top: 25px;
}
.block--dashed {
  border-bottom: 1px dashed rgb(228, 228, 228);
}
.block > .block {
  margin-top: 0;
}
.experiences table,
.education table {
  width: 100%;
  margin-bottom: 25px;
}
.experiences table p,
.education table p {
  margin-bottom: 0;
}
.experiences table td,
.education table td {
  vertical-align: top;
}
.experiences table td:last-child,
.education table td:last-child {
  text-align: right;
  min-width: 200px;
}

.experience {
  margin-bottom: 25px;
  padding-bottom: 25px;
}

.projects table,
.achievements table {
  width: 100%;
  margin-bottom: 10px;
}
.projects table td,
.achievements table td {
  vertical-align: top;
}
.projects table td:last-child,
.achievements table td:last-child {
  text-align: right;
  min-width: 100px;
}

.project,
.achievement {
  margin-bottom: 25px;
}
